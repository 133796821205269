@import './Variables.scss';

body {
  font-family: 'Montserrat', sans-serif;
  color: $color-tundora;
}

.product-header{
  margin: 0 30%;
}

.product-card{

  &__head{

    position: relative;

    &--box{
      width: 11vw;
      height: 11vw;
      position: absolute;
      right: 8%;
      border-radius: 50%;
      border: 1px solid $color-tundora;
      background-color: $color-wild-sand;
      
      img{
        padding: 6px 5px 5px 5px;
      }
    }

    &--title{
      font-weight: 700;
      font-size: 1.1rem;
    }

    &--ean{
      font-weight: 500;
      font-size: 1rem;
    }
  }

  &__details{
    background-color: $color-wild-sand;
    border-top-right-radius: 40px;
    box-shadow: inset 0px 5px 5px 0px rgba(0,0,0,0.22);

    &--list{
      li{
        font-weight: 400;
        font-size: 0.95rem;
        margin-bottom: 10px;
      }
      .remarks{
        font-size: 0.85rem;
      }
    }
  }
}

@media (min-width: 600px) {
  .product-header{
    margin: 0 35%;
  }
  .product-card{
    &__head{
      &--picture{
        padding: 0 15%;
      }

      &--box{
        width: 8vw;
        height: 8vw;
      }
    }

    &__details{
      border-top-right-radius: 60px;
    }
  }
}